import { accountSetupRouteNames } from '@/router/routes/accountSetupRoutes';

const accountSetupFlow = {
  [accountSetupRouteNames.WELCOME]: accountSetupRouteNames.QUALIFICATIONS,
  [accountSetupRouteNames.QUALIFICATIONS]: accountSetupRouteNames.AI,
  [accountSetupRouteNames.AI]: {
    agency: accountSetupRouteNames.AGENCY,
    normal: accountSetupRouteNames.BRANCHING,
  },
};

export const percentage = {
  [accountSetupRouteNames.WELCOME]: 1 / 4,
  [accountSetupRouteNames.QUALIFICATIONS]: 2 / 4,
  [accountSetupRouteNames.AI]: 3 / 4,
  [accountSetupRouteNames.AGENCY]: 4 / 4,
  [accountSetupRouteNames.BRANCHING]: 4 / 4,
};

export const getAccountSetupFlow = () => {
  return accountSetupFlow;
};
