<template lang="pug">
.om-onboarding-ai-feature.mb-6.mb-md-0
  wizard-top.mb-4.mb-md-5(
    v-if="!isOnMobileOnboarding"
    :show-progress="false"
    :show-back="true"
    @backClick="navigateBack"
  )
  .container
    .title
      wizard-title.small {{ $t('onboarding.wizard.choosePath.title1') }}
      wizard-title(v-html="$t('onboarding.aiFeature.title')")
    .d-flex.justify-content-center
      .block(style="margin-right: 60px")
        img.mb-4(:src="require('@/assets/admin/svg/onboarding/laptop-your-brand.svg')")
        wizard-title.medium.mb-5.fix-width(v-html="$t('onboarding.aiFeature.popupTitle')")
        om-button.fix-width(primary large @click="redirectToBranching") {{ $t('onboarding.wizard.choosePath.buttons.start') }}
      .block
        img.mb-4(:src="require('@/assets/admin/svg/onboarding/VIP-template.svg')")
        wizard-title.medium.mb-5(v-html="$t('onboarding.aiFeature.aiTitle')")
        om-button.fix-width(primary large @click="finishAndRedirect('ai')") {{ $t('onboarding.aiFeature.aiButton') }}
    .d-flex.flex-column.align-items-center(style="margin-top: 55px")
      om-body-text.mb-2.mt-2.gray-600(bt400xs) {{ $t('onboarding.wizard.choosePath.rightBottom.description') }}
      om-link(primary withIconRight)
        a.skip-wizard(@click="finishAndRedirect('dashboard')") {{ $t('onboarding.wizard.choosePath.rightBottom.link') }}
</template>

<script>
  import WizardTitle from '@/components/Wizard/Title.vue';
  import WizardTop from '@/components/Wizard/Top.vue';
  import wizardSharedMixin from '@/views/Wizard/shared';
  import { redirectToOnboardingBranching } from '@/router/routes/deletedroutes';
  import { track } from '@/services/xray';
  import SET_PROFILE_KEY from '@/graphql/SetProfileKey.gql';
  import { mapActions } from 'vuex';
  import navigationMixin from '../accountSetup-navigation';

  export default {
    components: { WizardTop, WizardTitle },
    mixins: [navigationMixin, wizardSharedMixin],

    computed: {
      isOnMobileOnboarding() {
        return this.$route.path.includes('mobile-onboarding');
      },
    },

    methods: {
      ...mapActions(['finishOnboarding']),
      async setInterestedFeature(value) {
        await this.$apollo.mutate({
          mutation: SET_PROFILE_KEY,
          variables: { key: 'interestedFeature', value },
        });
      },
      async redirectToBranching() {
        await this.setInterestedFeature('popup');
        const route = redirectToOnboardingBranching({});
        this.$router.push(route);
      },
      navigateBack() {
        this.$router.go(-1);
      },
      async finishAndRedirect(name) {
        track(`accountSetup-ai-finish-${name}`);
        const isAi = name === 'ai';
        await this.setInterestedFeature(isAi ? 'ai' : 'popup');
        if (!isAi) {
          await this.finishOnboarding();
        }
        this.$router.push({ name: isAi ? 'ai_features' : 'dashboard' });
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .om-onboarding-ai-feature
    .title
      margin-bottom: 28px
      text-align: center
      .small
        font-size: 18px
    .medium
      font-size: 24px
    .block
      min-width: 320px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      text-align: center
      .fix-width
        min-width: 188px
      .ai
        color: white
        padding-inline: 4px
        border-radius: 4px
        background: var(--gradient-om-gradient-purple, linear-gradient(100deg, #8444E1 47.3%, #AE4D97 100.03%))
</style>
