import UPDATE_WIZARD_PREFERENCES from '@/graphql/UpdateWizardPreferences.gql';

export default {
  components: { WizardTitle: () => import('@/components/Wizard/Title') },

  methods: {
    getSmartWizardPreferences(query) {
      if (this.buildSmartWizardQuery) {
        const { useCases } = this.buildSmartWizardQuery(query);
        return { useCases, type: 'smart-wizard' };
      }
      return {};
    },

    getWizardPreferences(query = null) {
      return this.getSmartWizardPreferences(query);
    },

    async updateWizardPreferences(inputs) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_WIZARD_PREFERENCES,
          variables: {
            input: inputs,
          },
        });
        if (inputs.skip) {
          this.$store.commit('skipWizard');
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
